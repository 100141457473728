<template>
  <div class="py-5"></div>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg w-4/5 mx-auto ">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Season Information
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Information on {{season.name}}
      </p>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Name
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{season.name}}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Season Start date
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{season.startDate}}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Season End date
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{season.endDate}}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
            Notes
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
           {{season.notes}}
          </dd>
        </div>
        <div class="sm:col-span-2">
        </div>
      </dl>
    </div>
  </div>
</template>


<script>

import { useStore } from 'vuex';
import {useRoute} from "vue-router";
import {computed} from "vue";

export default {
  name: "Seasons",
  setup() {
    const store = useStore()
    const route = useRoute()

    // Initial setup
    store.dispatch('fetchSeason', route.params.id)

    const season = computed(() => {
      return {
        ...store.getters.getSeason,
      }})

    return {
      store,
      season,

    }
  }
}
</script>
